import React from "react";
import { Calendar } from "primereact/calendar";

import Translate from "Components/Translate";
import { Controller } from "react-hook-form";
import moment from "moment";

const DateInput = ({ register, inputName, errors, control, oldValue }) => {
  const { name, title, required } = inputName;
  const isReq = required === undefined ? true : false;

  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>
        {getDate(oldValue) ? (
          <Controller
            name={name}
            control={control}
            defaultValue={getDate(oldValue)}
            render={({ field }) => (
              <Calendar
                id={field.name}
                value={new Date(field.value)}
                onChange={(e) => {
                  const value = moment(e.value).format("YYYY-MM-DD");
                  field.onChange(value);
                }}
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                showIcon
                autoComplete="off"
                size={10}
                showClear
              />
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Calendar
                onChange={(e) => {
                  const value = moment(e.value).format("YYYY-MM-DD");
                  field.onChange(value);
                }}
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                showIcon
                autoComplete="off"
                size={10}
                showClear
              />
            )}
          />
        )}

        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default DateInput;

const getDate = (param) => {
  console.log(param);
  try {
    return param.substring(0, 10);
  } catch {
    return null;
  }
};
