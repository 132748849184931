import { Badge, Grid, Group } from "@mantine/core";

import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { CancelBtn, DeliverBtn, ReadyBtn } from "./DfBtns";

// API
export const API = {
  list: "/back/deliveryForms",
  get: "/back/deliveryForm",
  edit: "/back/deliveryForm/",
  create: "/back/deliveryForm",
};

// Columns
export const COLUMNS = [
  {
    name: "N° delivary form",
    selector: "df_number",
  },
  {
    name: "N° purchase order",
    selector: "po_number",
  },
  {
    name: "N° estimate",
    selector: "estimate_number",
  },
  {
    header: "Status",
    Cell: ({ row }) => {
      const { status } = row.original;

      const color = getColorFromStatus(status);

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },
  {
    name: "Price",
    selector: "price",
  },
  {
    name: "Created at",
    selector: "created_at",
  },
  {
    header: "Actions",
    id: "actions",
    button: true,
    size: 80,
    Cell: ({ row }) => {
      const {
        code,
        df_number,
        to_pickup = false,
        to_cancel = false,
        to_deliver = false,
      } = row.original;

      const cancelUrl = `/back/deliveryForm/${code}`;
      const urlReady = `/back/deliveryForm/${code}/ready`;
      const urlDeliver = `/back/deliveryForm/${code}/inDelivery`;

      return (
        <Group gap="xs">
          <ReadyBtn url={urlReady} disabled={!to_pickup} />
          <DeliverBtn url={urlDeliver} disabled={!to_deliver} />
          <CancelBtn url={cancelUrl} disabled={!to_cancel} />
        </Group>
      );
    },
  },
];

const getColorFromStatus = (status) => {
  const statusColors = {
    Annulé: "red",
    Brouillon: "gray",
    "En preparation": "yellow",
    "A enlever": "orange",
    "En livraison": "blue",
    Reçu: "green",
  };

  return statusColors[status] || "gray";
};
