import { Badge, Group } from "@mantine/core";

import { CREATED_AT, CREATOR, PRICE_TTC, TAXI } from "Constant";

import { getCodeLabel } from "helper/getOptions";

import {
  ConfirmBtn,
  ConfirmPaymentBtn,
  CreateDelivery,
  CreateDeliveryFormBtn,
} from "./PurchaseOrderBtns";

// API
export const API = {
  list: "/back/purchaseorders",
};

// Columns
export const COLUMNS = [
  {
    name: "N° purchase order",
    selector: "po_number",
  },

  {
    name: "N° estimate",
    selector: "estimate_number",
  },
  {
    header: "Status",
    Cell: ({ row }) => {
      const { status } = row.original;

      const color = getColorFromStatus(status);

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },
  {
    name: PRICE_TTC,
    selector: "price_ttc",
  },

  {
    name: TAXI,
    selector: "taxi",
  },
  {
    name: CREATOR,
    selector: "creator",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 180,
    Cell: ({ row }) => {
      const {
        code,
        to_confirm = true,
        to_confirm_payment = true,
      } = row.original;

      const confirmPaymentUrl = `/back/purchaseorder/${code}/confirm-payment`;
      const createDeliveryFormUrl = `/back/purchaseorder/${code}/deliveryform`;

      return (
        <Group gap="xs">
          <ConfirmPaymentBtn
            url={confirmPaymentUrl}
            disabled={!to_confirm_payment}
          />
          <CreateDeliveryFormBtn
            url={createDeliveryFormUrl}
            disabled={!to_confirm}
          />
        </Group>
      );
    },
  },
];

const getColorFromStatus = (status) => {
  const statusColors = {
    Annulé: "red",
    Brouillon: "gray",
    Confirmé: "green",
    Payé: "indigo",
    "En preparation": "yellow",

    "En livraison": "orange",
    Pret: "blue",
    Clôturé: "cyan",
  };

  return statusColors[status] || "grape"; // Default color if status doesn't match
};
