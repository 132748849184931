import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import {
  CREATE_DELIVERY_FORM,
  EDIT_DELIVERY_FORM,
  MY_DELIVARY_FORM,
} from "Constant";

const DeliveryFormPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_DELIVARY_FORM];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} createBtn={false} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_DELIVERY_FORM}>
        {/* <AddItem addURL={API.create} items={CREATE_ITEMS} /> */}
      </ModalApp>

      <ModalApp type="edit" title={EDIT_DELIVERY_FORM}>
        {/* <EditItem itemsUpdate={EDIT_ITEMS} /> */}
      </ModalApp>
    </Fragment>
  );
};

export default DeliveryFormPage;
