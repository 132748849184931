import { Group } from "@mantine/core";
import { CancelBtn, ConfirmBtn } from "./BillBtns";

// API
export const API = {
  list: "/back/bills",
  get: "/back/bill",
  create: "/back/bill/",
};

// Columns
export const COLUMNS = [
  {
    name: "Name",
    selector: "complete_name",
  },
  {
    name: "address",
    selector: "address",
  },
  {
    name: "city",
    selector: "city",
  },
  {
    name: "vat amount",
    selector: "vat_amount",
  },
  {
    name: "price vat",
    selector: "price_vat",
  },
  {
    name: "price",
    selector: "price",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code, to_confirm = true, to_cancel = true } = row.original;
      const urlConfirmPayment = `/back/bill/${code}/confirm-payment`;
      const urlCancel = `/back/bill/${code}`;

      return (
        <Group gap="xs">
          {/* <ConfirmBtn url={urlConfirmPayment} disabled={!to_confirm} />
          <CancelBtn url={urlCancel} disabled={!to_cancel} /> */}
        </Group>
      );
    },
  },
];
