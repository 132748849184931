import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import styles from "./bill.module.css";

import logo from "../../assets/images/logo/logo-1.png";

import getRowsFromRes from "../../helper/getRowsFromRes";
import useFetchData from "../../hooks/useFetchData";

import StaticDataTable from "../../Components/DataTables/StaticDataTable";
import StaticTextRows from "../../Components/StaticData/StaticTextRows";
import DataNotFound from "../../Components/NotFound/DataNotFound";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Loading from "../../Components/Loading/Loading";
import PrintBtn from "../../Components/PrintBtn";

import { BILL, PRODUCTS, SERVICES } from "../../Constant";

const ReadBillPage = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { code } = useParams();
  const { data, isLoading, isError } = useFetchData(`/back/bill/${code}`);

  let billData = [];

  if (isLoading) return <Loading />;

  if (isError) return <DataNotFound />;

  if (!isLoading && !isError) {
    billData = getRowsFromRes(data);
  }

  const columnsProd = [
    { name: "Category", selector: "category" },
    { name: "Description", selector: "description" },
    { name: "Quantity", selector: "quantity" },
    { name: "Unit Price", selector: "unit_price" },
    { name: "VAT", selector: "vat" },
  ];

  const columnsServ = [
    { name: "Description", selector: "description" },
    { name: "Quantity", selector: "quantity" },
    { name: "service", selector: "service" },
    { name: "Unit Price", selector: "unit_price" },
  ];

  const billDetails = [
    { type: "H1", text: BILL },
    { type: "strong", text: billData.estimateNumber },
    { type: "", text: billData.createdAt },
  ];

  const addressAndDate = [
    { type: "strong", text: billData.address },
    { type: "", text: `${billData.city} ${billData.zipCode}` },
  ];

  const { products, services } = billData;

  return (
    <Fragment>
      <div>
        <Card>
          <CardBody>
            <div ref={componentRef}>
              <Row style={{ margin: "50px" }}>
                <Col className="col-7">
                  <img src={logo} className={styles["logo"]} alt="" />
                </Col>
                <Col className="col-5">
                  <StaticTextRows requiredData={billDetails} />
                </Col>
              </Row>
              <Row style={{ margin: "50px" }}>
                <Col className="col-7">
                  <StaticTextRows requiredData={addressAndDate} />
                </Col>
                {/* <Col className="col-5">
                  <ClientsDetails clientCode={billData.client} />
                </Col> */}
              </Row>
              <div style={{ margin: "50px" }}>
                {products.length && (
                  <Fragment>
                    <Breadcrumbs breadCrumbsProps={[PRODUCTS]} />
                    <StaticDataTable
                      newColumns={columnsProd}
                      newRows={products}
                    />
                  </Fragment>
                )}
                {services.length && (
                  <Fragment>
                    <Breadcrumbs breadCrumbsProps={[SERVICES]} />
                    <StaticDataTable
                      newColumns={columnsServ}
                      newRows={services}
                    />
                  </Fragment>
                )}
              </div>
            </div>
            <div className={styles["print-btn"]}>
              <PrintBtn onClick={handlePrint} />
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default ReadBillPage;
