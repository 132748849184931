import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import styles from "./estimate.module.css";
import { useNavigate } from "react-router-dom";

import logoSmarteo from "assets/images/logo/smarteo.jpeg";

import useFetchData from "hooks/useFetchData";

import StaticTextRows from "Components/StaticData/StaticTextRows";
import Breadcrumbs from "Components/Breadcrumbs";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import PrintBtn from "Components/PrintBtn";

import ClientsDetails from "./ClientsDetails";

import {
  TOTAL_PRICE_WITH_VAT,
  TOTAL_VAT_AMOUNT_TND,
  TOTAL_PRICE_TND,
  VAT_PERCENTAGE,
  SERVICES,
  DEVIS,
  TOTAL,
  PRODUCTS,
} from "Constant";
import StaticTextTable from "Components/StaticData/StaticTextTable";

import getRowRes from "./getRowRes";
import { Btn } from "AbstractElements";
import { Edit } from "react-feather";
import StaticTab from "./StaticTab";

const ReadEstimatePage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    data: estimatesData,
    isLoading,
    isError,
  } = useFetchData("/back/estimate/" + code);

  let estimateData;
  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    estimateData = getRowRes(estimatesData);
  }

  const columnsProd = [
    { header: "Catégorie", field: "category" },
    { header: "Description", field: "description" },
    { header: "Quantité", field: "quantity" },
    { header: "Prix Unitaire", field: "unit_price" },
    { header: "Réduction", field: "discount" },
    { header: "TVA", field: "vat" },
    {
      header: "Total",
      field: (rowData) => {
        const quantity = parseFloat(rowData.quantity);
        const unit_price = parseFloat(rowData.unit_price);
        const vat = parseFloat(rowData.vat);

        // Calculate the price before VAT
        const priceBeforeVat = quantity * unit_price;

        // Calculate final price including VAT
        const finalPrice = priceBeforeVat + (priceBeforeVat * vat) / 100;

        // Return the formatted price
        return finalPrice.toFixed(2); // To show 2 decimal places
      },
    },
  ];

  const columnsServ = [
    { header: "Service", field: "service" },
    { header: "Description", field: "description" },
    { header: "Quantité", field: "quantity" },
    { header: "Prix Unitaire", field: "unit_price" },
    {
      header: TOTAL,
      field: (rowData) => {
        const quantity = parseFloat(rowData.quantity);
        const unit_price = parseFloat(rowData.unit_price);

        // Calculate the price before VAT
        const finalPrice = quantity * unit_price;

        return finalPrice.toFixed(2); // To show 2 decimal places
      },
    },
  ];
  const devisData = [
    { type: "H1", text: DEVIS },
    { type: "strong", text: estimateData.estimateNumber },
    { type: "", text: estimateData.createdAt },
  ];

  const addressAndDate = [
    { type: "", text: `${estimateData.city} ${estimateData.zipCode}` },
    { type: "strong", text: estimateData.address },
  ];

  const totalData = [
    { label: TOTAL_PRICE_TND, value: estimateData.price },
    { label: TOTAL_VAT_AMOUNT_TND, value: estimateData.vatAmount },
    { label: TOTAL_PRICE_WITH_VAT, value: estimateData.priceVat },
    // { label: VAT_PERCENTAGE, value: estimateData.vat },
  ];
  const showService = estimateData.services.length ? true : false;

  const { status } = estimateData;
  const testStatus = () => {
    if (status === "canceled") {
      return false;
    }
    if (status === "draft") {
      return true;
    }
    if (status === "confirmed") {
      return false;
    }
    if (status === "paid") {
      return false;
    }
    if (status === "created") {
      return true;
    }
    if (status === "ready") {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <div ref={componentRef}>
            <Row style={{ marginLeft: "50px", marginTop: "50px" }}>
              <Col className="col-7">
                <div style={{ marginLeft: "20px" }}>
                  <img src={logoSmarteo} className={styles["logo"]} alt="" />
                  <StaticTextRows requiredData={addressAndDate} />
                </div>
                {/* <h1>
                  <strong> Smarteo </strong>
                </h1> */}
              </Col>
              <Col className="col-5">
                <StaticTextRows requiredData={devisData} />
              </Col>
            </Row>

            <div style={{ margin: "50px" }}>
              <Breadcrumbs breadCrumbsProps={["Produits"]} />
              <StaticTab
                newColumns={columnsProd}
                newRows={estimateData.products}
              />

              {showService && (
                <>
                  <Breadcrumbs breadCrumbsProps={[SERVICES]} />
                  <StaticTab
                    newColumns={columnsServ}
                    newRows={estimateData.services}
                  />
                </>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticTextTable requiredData={totalData} title={TOTAL} />
            </div>
          </div>

          <div className={styles["print-btn"]}>
            <PrintBtn onClick={handlePrint} />

            {/* {testStatus() && ( */}
            <Btn
              attrBtn={{
                onClick: () => {
                  navigate(`/edit-estimate/${code}`);
                },
                color: "info",
                variant: "contained",
                className: "btn btn-secondary btn-sm",
                disabled: !testStatus(),
              }}
            >
              <Edit />
            </Btn>
            {/* )} */}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReadEstimatePage;
